<template>
    <div style="min-height: calc(100vh - 158px);" class="card">
        <div style="background: #DBE7F2" class="card p-2">
            <TitleButton
                :showBtn="false"
                title="Trial Balance"
            />

            <div class="col-12 mt-2">
                <DateQuerySetter
                    btnTitle="Go"
                    @onClickGo="onClickGo"
                />
            </div>
        </div>

        <div class="col-12">
            <div class="d-flex justify-content-end px-2">
                <button 
                    @click="exportTable"
                    class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect"
                >
                    Export
                </button>
            </div>
        </div>

        <div class="card mt-1 px-2">
            <TrialBalanceTable :balances="trialBalanceData"/>
        </div>
        <Loader v-if="reportLoading"/>
    </div>
</template>

<script>
import handleReport from '@/services/modules/accountingReport'
import { inject } from 'vue'

import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import TrialBalanceTable from '@/components/molecule/company/trial-balance/TrialBalanceTable'
import Loader from '@/components/atom/LoaderComponent'

export default {
    name: 'TrialBalance',

    components: {
        TitleButton,
        Loader,
        DateQuerySetter,
        TrialBalanceTable
    },

    data: () => ({
        trialBalanceData: []
    }),

    computed: {
        companyId() {
            return this.$route.params.companyId
        },
        start() {
            return this.$route.query.start
        },
        end() {
            return this.$route.query.end
        },
        token () {
            return localStorage.getItem('token')
        }
    },

    methods: {
        onClickGo() {
            this.getDateWiseTrailBalance(this.getQuery())
        },

        exportTable () {
            let url = `${process.env.VUE_APP_BASE_URL}/export/accounting/trial-balance?company_id=${this.companyId}&start_date=${this.start}&end_date=${this.end}&_token=${this.token}`
            let a = document.createElement("a")
            a.setAttribute("href", url)
            a.click()
        },

        getQuery () {
            let query = '?company_id=' + this.companyId
            if(!this.start && !this.end) return query
            if(this.start) query += '&start_date='+this.start
            if(this.end) query += '&end_date='+this.end
            // query += '&offset='+this.offset
            return query
        },

        async getDateWiseTrailBalance(query = '') {
            try {
                this.reportLoading = true
                let res = await this.getTrialBalance(query)
                if(!res.status) {
                    this.showError(res.response.message)
                }
                if(res.status) {
                    this.trialBalanceData = res.data
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response?.data) {
                    this.showError(err.response.data.message)
                    return
                }
            } finally {
                this.reportLoading = false
            }
        },
    },

    setup() {
        const showError =  inject('showError');
        const showSuccess =  inject('showSuccess');
        // const store = useStore()
        const {
            getTrialBalance,
            reportLoading,
            getLedger
        } = handleReport()


        return {
            getTrialBalance,
            reportLoading,
            showError,
            showSuccess,
            getLedger,
        }
    },

    mounted () {
        this.getDateWiseTrailBalance(this.getQuery())
    }

}
</script>